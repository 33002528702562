<template>
  <div :class="`m-3 wrapper wrapper--border wrapper--default`">
    <span class="m-1 ">Full cache clear: <button class="employee_download" @click="clearCache">Clear Cache</button></span>
  </div>
</template>
<script>
import request from '@/functions/request.js';

export default {
    methods: {
        clearCache() {
            request(`clear-cache`).then(({ message }) => {
                alert(message)
            })
        }
    }
    

};
</script>
